import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { ToggleButton, ToggleButtonGroup, Spinner } from "react-bootstrap";
import { Game, Prop } from "../types";

interface User {}

interface PropGradeInput {
  contestId: string;
  game: Game;
  userStates: { [index: number]: User };
  propCreator: string;
  currentUser: string;
}

export function PropGrade(input: PropGradeInput) {
  let optionA = "";
  let optionB = "";
  var initialWinner = "";
  let prop = input.game.props[input.propCreator];
  if (prop) {
    optionA = prop.choices[0];
    optionB = prop.choices[1];
    initialWinner = prop.winner;
  }

  const [winner, setWinner] = useState(initialWinner);
  const [isSaving, setIsSaving] = useState(false);

  async function handleChange(event: string) {
    setWinner(event);
    setIsSaving(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const options = {
        headers: {
          Authorization: jwtToken,
        },
        body: {
          pick: event,
        },
      };
      await API.post(
        "api",
        "contests/" +
          input.contestId +
          "/props/" +
          input.game.id +
          "/" +
          btoa(input.propCreator) +
          "/grade",
        options
      );

      setIsSaving(false);
    } catch (e) {
      alert(e);
      setIsSaving(false);
    }
  }

  function allPicksLocked(prop: Prop, creator: string) {
    for (const user in input.userStates) {
      if (user !== creator) {
        let pick = prop.picks[user];
        if (!pick) {
          return false;
        }
        if (!pick.locked) {
          return false;
        }
      }
    }
    return true;
  }

  if (prop) {
    if (allPicksLocked(prop, input.propCreator)) {
      if (prop.winner !== null) {
        return <td key="winner">{prop.winner}</td>;
      } else {
        let optionAClassName = "";
        let optionBClassName = "";
        if (optionA !== winner) {
          optionAClassName = "ToggleNotSelected";
        }
        if (optionB !== winner) {
          optionBClassName = "ToggleNotSelected";
        }
        return (
          <td key="winner">
            <ToggleButtonGroup
              type="radio"
              name="grade"
              value={winner}
              onChange={handleChange}
            >
              <ToggleButton
                value={optionA}
                className={optionAClassName}
                disabled={isSaving}
              >
                {isSaving && winner === optionA && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {optionA}
              </ToggleButton>
              <ToggleButton
                value={optionB}
                className={optionBClassName}
                disabled={isSaving}
              >
                {isSaving && winner === optionB && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {optionB}
              </ToggleButton>
            </ToggleButtonGroup>
          </td>
        );
      }
    } else {
      return <th key="winner">WAITING FOR LOCKS</th>;
    }
  } else {
    return <th key="winner">Nothing to grade yet</th>;
  }
}
