import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Home.css";
import { Auth, API } from "aws-amplify";
import { Contest } from "../types";

export function Home() {
  const [contests, setContests] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function loadContests() {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      setCurrentEmail(user.attributes.email);

      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const options = {
        headers: {
          Authorization: jwtToken,
        },
      };

      try {
        const contests = await API.get("api", "contests", options);
        setContests(contests);
        setLoading(false);
      } catch (e) {
        alert(e);
        setLoading(false);
      }
    }

    loadContests();
  }, []);

  function renderLander() {
    return (
      <div className="lander">
        <h1>Prop Bet Anything</h1>
        <p>Make prop bets with your friends</p>
      </div>
    );
  }

  function renderContests() {
    return (
      <div className="contests">
        <ListGroupItem key="new" action href="/contests/new">
          <h4>
            <b>{"\uFF0B"}</b> Create a new contest
          </h4>
        </ListGroupItem>
        <ListGroup>{!isLoading && renderContestsList()}</ListGroup>
      </div>
    );
  }

  function renderContest(contest: Contest) {
    let outcomeClassName = "";
    let outcomeDollars = contest.stakes * contest.outcome;
    if (contest.outcome === 1) {
      outcomeClassName = "winner";
    } else if (contest.outcome >= 1) {
      outcomeClassName = "bigWinner";
    } else if (contest.outcome === -1) {
      outcomeClassName = "loser";
    } else if (contest.outcome <= -1) {
      outcomeClassName = "bigLoser";
    }
    let name = contest.name.trim().split("\n")[0];
    if (outcomeClassName === "") {
      return (
        <React.Fragment key={contest.contest_id}>
          <LinkContainer to={`/contests/${contest.contest_id}`}>
            <ListGroup.Item action>
              <b>{name}</b>
            </ListGroup.Item>
          </LinkContainer>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={contest.contest_id}>
          <LinkContainer
            key={contest.id}
            to={`/contests/${contest.contest_id}`}
          >
            <ListGroup.Item action className={outcomeClassName}>
              <b>{name}</b>
              {" : \t\tResult : $" + outcomeDollars}
            </ListGroup.Item>
          </LinkContainer>
        </React.Fragment>
      );
    }
  }

  function renderContestsList() {
    let initialContestList: Contest[] = [];
    return initialContestList
      .concat(contests)
      .map((contest, _i) => renderContest(contest));
  }

  return (
    <div className="Home">
      {currentEmail !== "" ? renderContests() : renderLander()}
    </div>
  );
}
