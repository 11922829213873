import React, { useState } from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import { LoaderButton } from "../components/LoaderButton";
import { Auth, API } from "aws-amplify";
import { Game } from "../types";

interface CreatePropInput {
  contestId: string;
  game: Game;
  currentUser: string;
}

export function CreateProp(input: CreatePropInput) {
  const [optionA, setOptionA] = useState("");
  const [optionB, setOptionB] = useState("");
  const [isLoading, setLoading] = useState(false);

  function handleOptionAChange(event: React.ChangeEvent<HTMLInputElement>) {
    setOptionA(event.target.value);
  }

  function handleOptionBChange(event: React.ChangeEvent<HTMLInputElement>) {
    setOptionB(event.target.value);
  }

  async function submitProp(event: React.FormEvent) {
    event.preventDefault();
    setLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const choices = {
        headers: {
          Authorization: jwtToken,
        },
        body: {
          choices: [optionA, optionB],
        },
      };
      await API.post(
        "api",
        "contests/" + input.contestId + "/props/" + input.game.id,
        choices
      );
      setLoading(false);
    } catch (e) {
      alert(e);
      setLoading(false);
    }
  }

  const formDisabled =
    optionA.length > 0 && optionB.length > 0 && optionA !== optionB;

  return (
    <td>
      <Form inline onSubmit={submitProp}>
        <FormGroup controlId="optionA">
          <FormControl
            type="text"
            placeholder="Option A"
            onChange={handleOptionAChange}
            value={optionA}
          />
        </FormGroup>{" "}
        <FormGroup controlId="optionB">
          <FormControl
            type="text"
            placeholder="Option B"
            onChange={handleOptionBChange}
            value={optionB}
          />
        </FormGroup>{" "}
        <LoaderButton
          variant="primary"
          disabled={!formDisabled}
          type="submit"
          isLoading={isLoading}
          text="Create Prop"
          loadingText="Create Prop"
          className=""
          size="sm"
        />
      </Form>
    </td>
  );
}
