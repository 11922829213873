import React from "react";
import { Route, Switch } from "react-router-dom";
import { AppliedRoute } from "./components/AppliedRoute";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { UnauthenticatedRoute } from "./components/UnauthenticatedRoute";

import { NotFound } from "./containers/NotFound";
import { Login } from "./containers/Login";
import { Home } from "./containers/Home";
import { Signup } from "./containers/Signup";
import { NewContest } from "./containers/NewContest";
import { Contest } from "./containers/Contest";
import { AuthState } from "./types";

interface RoutesInput {
  authState: AuthState;
}

export function Routes(input: RoutesInput) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} props={input.authState} />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        props={input.authState}
      />
      <UnauthenticatedRoute
        path="/signup"
        exact
        component={Signup}
        props={input.authState}
      />
      <AuthenticatedRoute
        path="/contests/new"
        exact
        component={NewContest}
        props={input.authState}
      />
      <AuthenticatedRoute
        path="/contests/:id"
        exact
        component={Contest}
        props={input.authState}
      />
      <Route component={NotFound} />
    </Switch>
  );
}
