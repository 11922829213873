import React, { useState, useEffect, useReducer, useRef } from "react";
import { Auth, API } from "aws-amplify";
import { useParams } from "react-router-dom";
import { Card, Table } from "react-bootstrap";

import { AcceptInviteButton } from "./AcceptInviteButton";
import { DeclineInviteButton } from "./DeclineInviteButton";
import { GameComponent } from "./GameComponent";
import { LockButton } from "./LockButton";
import "./Contest.css";
import Sockette from "sockette";
import config from "../config";
import { Game, Prop, User } from "../types";

interface ContestMetadata {
  name: string;
  creator: string;
  stakes: number;
}

type ActionType = {
  type: "api";
  id: string;
  name: string;
  props: { [email: string]: Prop };
};

interface ParamType {
  id: string;
}

export function Contest() {
  let { id } = useParams<ParamType>();
  const [contestMetadata, setContestMetadata] = useState({
    name: "",
    stakes: 0,
    creator: "",
  });
  const initialUserState: { [email: string]: User } = {};
  const [contestStatus, setContestStatus] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [userStates, setUserStates] = useState(initialUserState);
  const [websocket, setWebsocket] = useState<Sockette>();
  const [games, gameDispatch] = useReducer(gameReducer, []);

  //only depends on the URL, so should load once per page
  useEffect(() => {
    async function getContest() {
      const user = await Auth.currentAuthenticatedUser();
      setCurrentEmail(user.attributes.email);

      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const options = {
        headers: {
          Authorization: jwtToken,
        },
      };
      const contest = await API.get("api", "contests/" + id, options);
      const metadata: ContestMetadata = {
        name: contest.name,
        creator: contest.creator,
        stakes: contest.stakes,
      };
      setContestMetadata(metadata);
      setUserStates(contest.user_states);
      setContestStatus(contest.user_states[contest.user_email]);

      Object.keys(contest.games).forEach((key) => {
        let game = contest.games[key];
        gameDispatch({
          type: "api",
          id: key,
          name: game.name,
          props: game.props,
        });
      });
    }

    async function openContestStream() {
      const user = await Auth.currentAuthenticatedUser();
      let jwtToken = user.signInUserSession.accessToken.jwtToken;

      let websocketDomainName = config.apiGateway.WEBSOCKET_URL;
      setWebsocket(
        new Sockette(
          websocketDomainName +
            "?token=" +
            jwtToken +
            "&contestId=" +
            id +
            "&userId=" +
            user.attributes.email,
          {
            timeout: 5e3,
            maxAttempts: 1,
            onopen: (e) => console.log("Websocket Connected:", e),
            onmessage: (e) => {
              let contest = JSON.parse(e.data);
              setContestMetadata({
                name: contest.name,
                creator: contest.creator,
                stakes: contest.stakes,
              });
              setUserStates(contest.user_states);
              setContestStatus(contest.user_states[contest.user_email]);

              Object.keys(contest.games).forEach((key) => {
                let game = contest.games[key];
                gameDispatch({
                  type: "api",
                  id: key,
                  name: game.name,
                  props: game.props,
                });
              });
            },
            onreconnect: (e) => console.log("Reconnecting...", e),
            onmaximum: (e) => console.log("Stop Attempting!", e),
            onclose: (e) => console.log("Closed!", e),
            onerror: (e) => console.log("Error:", e),
          }
        )
      );
    }

    getContest();
    openContestStream();
  }, [id]);

  useInterval(() => {
    if (websocket) {
      websocket.json({ type: "ping" });
    }
  }, 60 * 1000); //every minute

  let header_users = Object.keys(userStates).map((user, i) => {
    let userStr = "";
    let userState = userStates[user];
    if (userState === "AwaitingReply") {
      userStr = user + " (Awaiting Reply)";
    } else if (userState === "Declined") {
      userStr = user + "(Declined)";
    } else {
      userStr = user;
    }

    return (
      <th key={i}>
        <b>{userStr}</b>
      </th>
    );
  });

  let lock_contest_buttons = Object.keys(userStates).map((user, i) => {
    if (user === currentEmail) {
      if (userStates[user] === "Locked") {
        return <th key={i}>LOCKED</th>;
      } else {
        return (
          <th key={i}>
            <LockButton
              contestId={id}
              currentEmail={currentEmail}
              games={games}
              userStates={userStates}
            />
          </th>
        );
      }
    } else {
      let lockedStr = "";
      if (userStates[user] === "Locked") {
        lockedStr = "LOCKED";
      }
      return <th key={i}>{lockedStr}</th>;
    }
  });

  let renderedGames;
  if (contestStatus === "AwaitingReply") {
    renderedGames = games.map((game: Game, i: number) => {
      return (
        <React.Fragment key={i}>
          <tr>
            <th>{game.name}</th>
            <th key="winner"></th>
          </tr>
        </React.Fragment>
      );
    });
  } else {
    renderedGames = games.map((game: Game, i: number) => {
      return (
        <GameComponent
          key={i}
          contestId={id}
          game={game}
          userStates={userStates}
          currentUser={currentEmail}
        />
      );
    });
  }

  return (
    <div className="Contest">
      <Card style={{ width: "48rem" }}>
        <Card.Body>
          <Card.Header>
            <b>{contestMetadata.name}</b>
          </Card.Header>
          <Card.Text>
            <br />
            <b>Stakes</b>: ${contestMetadata.stakes} per-pick
            <br />
            <b>Created by </b>: {contestMetadata.creator}
          </Card.Text>
          {contestStatus === "AwaitingReply" ? (
            <React.Fragment>
              <AcceptInviteButton
                contestId={id}
                setContestStatus={setContestStatus}
              />
              <DeclineInviteButton
                contestId={id}
                setContestStatus={setContestStatus}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <b>State</b> : {contestStatus}
            </React.Fragment>
          )}
        </Card.Body>
      </Card>
      <Table key="contest-users">
        <thead>
          <tr>
            <th key="leading"></th>
            {lock_contest_buttons}
            <th key="trailing"></th>
          </tr>
          <tr>
            <th key="leading"></th>
            {header_users}
            <th key="winner">WINNER</th>
          </tr>
        </thead>
        <tbody>{renderedGames}</tbody>
      </Table>
    </div>
  );

  function gameReducer(state: Game[], action: ActionType) {
    switch (action.type) {
      case "api": {
        const newGame = {
          id: action.id,
          name: action.name,
          props: action.props,
        };
        const newState = [];
        var pushedNew = false;
        state.forEach((existingGame: Game) => {
          if (existingGame.id !== action.id) {
            newState.push(existingGame);
          } else {
            //to preserve order
            newState.push(newGame);
            pushedNew = true;
          }
        });
        if (!pushedNew) {
          //add at end
          newState.push(newGame);
        }
        return newState;
      }
      default: {
        console.log("GameReducer default case hit!");
        return state;
      }
    }
  }

  function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        if (savedCallback && savedCallback.current) {
          savedCallback.current();
        }
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
}
