const staging = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.staging.propbetanything.com/",
    WEBSOCKET_URL:
    "wss://ws.staging.propbetanything.com/",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_BnPg7B4k5",
    APP_CLIENT_ID: "1i5pj6iroi2psej6clhtusme2i",
    IDENTITY_POOL_ID: "us-west-2:8320db46-4bcb-448f-b8ea-94348fb269b7",
  },
};

const prod = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://api.propbetanything.com/",
    WEBSOCKET_URL: "wss://ws.propbetanything.com/",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_F85EBvZuq",
    APP_CLIENT_ID: "67sg7a8f1t35fe9pk9k4uo1k8f",
    IDENTITY_POOL_ID: "us-west-2:f0094dd8-5d8c-42aa-95e6-f2934c367068",
  },
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : staging;

export default {
  ...config,
};
