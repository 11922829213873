import React, { useState } from "react";
import { Form, Card } from "react-bootstrap";
import { LoaderButton } from "../components/LoaderButton";
import "./NewContest.css";
import { Auth, API } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";

export function NewContest(newContestState: RouteComponentProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [stakes, setStakes] = useState(0);
  let initialUsers: string[] = [];
  let initialGames: string[] = [];
  const [users, setUsers] = useState(initialUsers);
  const [games, setGames] = useState(initialGames);

  function validateForm() {
    return (
      name.length > 0 && stakes >= 0 && users.length > 0 && games.length > 0
    );
  }

  function handleInviteChange(idx: number) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInvites: string[] = users.map((invite, i) => {
        if (idx !== i) {
          return invite;
        }
        return event.target.value;
      });
      setUsers(newInvites);
    };
  }

  function handleAddInvite() {
    setUsers(users.concat([""]));
  }

  function handleRemoveInvite(idx: number) {
    return (_event: React.MouseEvent) => {
      setUsers(users.filter((_invite, i) => idx !== i));
    };
  }

  function handleGameChange(idx: number) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const newGames: string[] = games.map((game, i) => {
        if (idx !== i) {
          return game;
        }
        return event.target.value;
      });
      setGames(newGames);
    };
  }

  function handleAddGame() {
    setGames(games.concat([""]));
  }

  function handleRemoveGame(idx: number) {
    return (_event: React.MouseEvent) => {
      setGames(games.filter((_game, i) => idx !== i));
    };
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function handleStakesChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStakes(parseInt(event.target.value));
  }

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    setIsLoading(true);

    try {
      let newContest = await Auth.currentAuthenticatedUser().then((user) => {
        let jwtToken = user.signInUserSession.idToken.jwtToken;
        const options = {
          body: {
            name: name,
            stakes: stakes,
            users: users,
            games: games,
          },
          headers: {
            Authorization: jwtToken,
          },
        };
        return API.post("api", "contests", options);
      });
      setIsLoading(false);
      newContestState.history.push("/contests/" + newContest.guid);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="NewContest">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Contest Name</Form.Label>
              <Form.Control onChange={handleNameChange} value={name} />
            </Form.Group>
            <Form.Group controlId="stakes">
              <Form.Label>Stakes ($)</Form.Label>
              <Form.Control onChange={handleStakesChange} value={stakes} />
            </Form.Group>
            <Form.Label>Players</Form.Label>
            <br />
            {users.map((invite, idx) => (
              <div className="invite" key={idx}>
                <input
                  type="text"
                  placeholder={`Invite #${idx + 1} name`}
                  value={invite}
                  onChange={handleInviteChange(idx)}
                />
                <button
                  type="button"
                  onClick={handleRemoveInvite(idx)}
                  className="small"
                >
                  -
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddInvite} className="small">
              Add Player
            </button>
            <p></p>
            <Form.Label>Games</Form.Label>
            <br />
            {games.map((game, idx) => (
              <div className="game" key={idx}>
                <input
                  type="text"
                  placeholder={`Game #${idx + 1} name`}
                  value={game}
                  onChange={handleGameChange(idx)}
                />
                <button
                  type="button"
                  onClick={handleRemoveGame(idx)}
                  className="small"
                >
                  -
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddGame} className="small">
              Add Game
            </button>
            <LoaderButton
              variant="primary"
              size="lg"
              disabled={!validateForm()}
              type="submit"
              isLoading={isLoading}
              text="Create"
              loadingText="Creating…"
              className=""
            />
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
