import React from "react";
import { PickProp } from "./PickProp";
import { CreateProp } from "./CreateProp";
import { PropGrade } from "./PropGrade";
import { Game, User } from "../types";
import { GrUnlock } from "react-icons/gr";

interface GameInput {
  contestId: string;
  game: Game;
  userStates: { [email: string]: User };
  currentUser: string;
}

export function GameComponent(gameInput: GameInput) {
  let extra_headings = Object.keys(gameInput.userStates).map((creator, i) => {
    return <th key={i}></th>;
  });

  let row_per_creator = Object.keys(gameInput.userStates).map((creator, i) => {
    let column_per_picker = Object.keys(gameInput.userStates).map(
      (picker, i) => {
        let prop = gameInput.game.props[creator];

        if (prop) {
          let className = "";
          let pick = prop.picks[picker];
          if (creator === gameInput.currentUser) {
            if (creator === picker) {
              if (prop.outcome === 1) {
                className = "winner";
              } else if (prop.outcome > 1) {
                className = "bigWinner";
              } else if (prop.outcome === -1) {
                className = "loser";
              } else if (prop.outcome < -1) {
                className = "bigLoser";
              }
              //I have already created this prop
              return (
                <td key={i} className={className}>
                  House
                </td>
              );
            } else {
              if (pick) {
                //this is someone else's pick on my prop
                if (prop.winner) {
                  if (pick.chosen && pick.chosen.Picked === prop.winner) {
                    className = "winner";
                  } else {
                    className = "loser";
                  }
                }
                if (pick.locked) {
                  return (
                    <td key={i} className={className}>
                      {pick.chosen.Picked}
                    </td>
                  );
                } else {
                  return (
                    <td key={i}>
                      {pick.chosen.Picked} <GrUnlock />
                    </td>
                  );
                }
              } else {
                return <td key={i}>No pick</td>;
              }
            }
          } else {
            if (creator === picker) {
              if (prop.winner) {
                let players = Object.keys(gameInput.userStates);
                let numPlayers = players.length;
                let winningPicks = 0;
                for (var index in players) {
                  const player = players[index];
                  if (player !== creator) {
                    let pick = prop.picks[player];
                    if (
                      pick &&
                      pick.chosen &&
                      pick.chosen.Picked &&
                      pick.chosen.Picked === prop.winner
                    ) {
                      winningPicks += 1;
                    }
                  }
                }
                let houseResult = numPlayers - 1 - winningPicks * 2;
                if (houseResult === 1) {
                  className = "winner";
                } else if (houseResult > 1) {
                  className = "bigWinner";
                } else if (houseResult === -1) {
                  className = "loser";
                } else if (houseResult < -1) {
                  className = "bigLoser";
                }
              }

              return (
                <td key={i} className={className}>
                  House
                </td>
              );
            } else {
              if (picker === gameInput.currentUser) {
                return (
                  <PickProp
                    key={i}
                    contestId={gameInput.contestId}
                    game={gameInput.game}
                    propCreator={creator}
                    currentUser={gameInput.currentUser}
                  />
                );
              } else {
                if (pick && pick.chosen) {
                  if (prop.winner) {
                    if (pick.chosen.Picked === prop.winner) {
                      className = "winner";
                    } else {
                      className = "loser";
                    }
                  }
                  return (
                    <td key={i} className={className}>
                      {pick.chosen.Picked}
                    </td>
                  );
                } else {
                  return <td key={i}>Hidden Pick</td>;
                }
              }
            }
          }
        } else {
          //prop not created yet
          if (creator === gameInput.currentUser) {
            if (creator === picker) {
              //I need to create
              return (
                <CreateProp
                  key={i}
                  contestId={gameInput.contestId}
                  game={gameInput.game}
                  currentUser={gameInput.currentUser}
                />
              );
            } else {
              //they are waiting for me to create
              return <td key={i}>Prop not created</td>;
            }
          } else {
            if (creator === picker) {
              return <td key={i}>Awaiting a prop</td>;
            } else {
              return <td key={i}>Prop not created</td>;
            }
          }
        }
      }
    );

    return (
      <tr key={i}>
        <th>{creator}</th>
        {column_per_picker}
        <PropGrade
          contestId={gameInput.contestId}
          game={gameInput.game}
          userStates={gameInput.userStates}
          propCreator={creator}
          currentUser={gameInput.currentUser}
        />
      </tr>
    );
  });

  return (
    <React.Fragment>
      <tr>
        <th>{gameInput.game.name}</th>
        {extra_headings}
        <th key="winner"></th>
      </tr>
      {row_per_creator}
    </React.Fragment>
  );
}
