import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, Button } from "react-bootstrap";
import { Routes } from "./Routes";
import { Auth } from "aws-amplify";
import "./App.css";
import { RouteComponentProps } from "react-router-dom";

export function App(appState: RouteComponentProps) {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    async function userAuth() {
      try {
        const session = await Auth.currentSession();
        setEmail(session.getIdToken().payload.email);
        setAuthenticated(true);
      } catch (e) {
        if (e !== "No current user") {
          alert(e);
        }
      }
    }

    userAuth();
  });

  async function handleLogout(_event: React.MouseEvent<HTMLDivElement>) {
    await Auth.signOut();
    setAuthenticated(false);
    appState.history.push("/login");
  }

  const authState = {
    isAuthenticated: isAuthenticated,
    setAuthenticated: setAuthenticated,
  };

  return (
    <div className="App">
      <Navbar bg="primary" variant="dark" collapseOnSelect>
        <Navbar.Brand href="/">Prop Bet Anything</Navbar.Brand>
        <Nav className="mr-auto" />
        <Nav>
          {isAuthenticated ? (
            <React.Fragment>
              <Button disabled>{email}</Button>
              <Button variant="info" onClick={handleLogout}>
                Logout
              </Button>
            </React.Fragment>
          ) : (
            <Fragment>
              <Nav.Link href="/signup">Signup</Nav.Link>
              <Nav.Link href="/login">Login</Nav.Link>
            </Fragment>
          )}
        </Nav>
      </Navbar>
      <Routes authState={authState} />
    </div>
  );
}

export default withRouter(App);
