import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "./LoaderButton.css";

interface ButtonInput {
  isLoading: boolean;
  text: string;
  loadingText: string;
  className: string;
  disabled: boolean;
  variant: string;
  type: string;
  size: "sm" | "lg" | undefined;
  block?: any;
}

export function LoaderButton(buttonInput: ButtonInput) {
  return (
    <Button
      className={`LoaderButton ${buttonInput.className}`}
      disabled={buttonInput.disabled || buttonInput.isLoading}
      variant={buttonInput.variant}
      type={buttonInput.type}
      size={buttonInput.size}
      block
    >
      {buttonInput.isLoading && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {!buttonInput.isLoading ? buttonInput.text : buttonInput.loadingText}
    </Button>
  );
}
