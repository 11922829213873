import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { ToggleButton, ToggleButtonGroup, Spinner } from "react-bootstrap";
import { Game, Prop } from "../types";

interface PickPropInput {
  contestId: string;
  game: Game;
  propCreator: string;
  currentUser: string;
}

export function PickProp(input: PickPropInput) {
  const currentEmail = input.currentUser;
  const prop: Prop = input.game.props[input.propCreator];
  const optionA: string = prop.choices[0];
  const optionB: string = prop.choices[1];

  var initialCurrentChoice = "";
  if (currentEmail in prop.picks) {
    initialCurrentChoice = prop.picks[currentEmail].chosen.Picked;
  }

  const [currentChoice, setCurrentChoice] = useState(initialCurrentChoice);
  const [isSaving, setIsSaving] = useState(false);

  async function handleChange(event: string) {
    setCurrentChoice(event);
    setIsSaving(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const options = {
        headers: {
          Authorization: jwtToken,
        },
        body: {
          pick: event,
        },
      };
      await API.post(
        "api",
        "contests/" +
          input.contestId +
          "/props/" +
          input.game.id +
          "/" +
          btoa(input.propCreator),
        options
      );

      setIsSaving(false);
    } catch (e) {
      alert(e);
      setIsSaving(false);
    }
  }

  let pick = prop.picks[currentEmail];

  if (pick && pick.locked) {
    let className = "";
    if (prop.outcome === 1) {
      className = "winner";
    } else if (prop.outcome > 1) {
      className = "bigWinner";
    } else if (prop.outcome === -1) {
      className = "loser";
    } else if (prop.outcome < -1) {
      className = "bigLoser";
    }
    return <td className={className}>{pick.chosen.Picked}</td>;
  } else {
    let optionAClassName = "";
    let optionBClassName = "";
    if (optionA !== currentChoice) {
      optionAClassName = "ToggleNotSelected";
    }
    if (optionB !== currentChoice) {
      optionBClassName = "ToggleNotSelected";
    }
    return (
      <td>
        <ToggleButtonGroup
          type="radio"
          name="prop"
          value={currentChoice}
          onChange={handleChange}
        >
          <ToggleButton
            value={optionA}
            className={optionAClassName}
            disabled={isSaving}
          >
            {isSaving && currentChoice === optionA && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {optionA}
          </ToggleButton>
          <ToggleButton
            value={optionB}
            className={optionBClassName}
            disabled={isSaving}
          >
            {isSaving && currentChoice === optionB && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {optionB}
          </ToggleButton>
        </ToggleButtonGroup>
      </td>
    );
  }
}
