import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { Button, Spinner } from "react-bootstrap";

import "./Contest.css";

interface DeclineInviteButtonInput {
  contestId: string;
  setContestStatus(status: string): void;
}

export function DeclineInviteButton(
  declineInviteButtonInput: DeclineInviteButtonInput
) {
  const [isSaving, setIsSaving] = useState(false);

  async function declineInvite(_event: React.MouseEvent<HTMLElement>) {
    try {
      setIsSaving(true);
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const options = {
        headers: {
          Authorization: jwtToken,
        },
      };
      await API.post(
        "api",
        "contests/" + declineInviteButtonInput.contestId + "/decline",
        options
      );
      declineInviteButtonInput.setContestStatus("Declined");
      setIsSaving(false);
    } catch (e) {
      alert(e);
      setIsSaving(false);
    }
  }

  return (
    <Button variant="warning" onClick={declineInvite} disabled={isSaving}>
      {isSaving && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      Decline
    </Button>
  );
}
