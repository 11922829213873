import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { Button, Spinner } from "react-bootstrap";

import "./Contest.css";
import { Game, User } from "../types";

interface LockButtonInput {
  contestId: string;
  currentEmail: string;
  games: Game[];
  userStates: { [index: number]: User };
}

export function LockButton(lockButtonInput: LockButtonInput) {
  const [isSaving, setIsSaving] = useState(false);

  async function lockPicks(_event: React.MouseEvent<HTMLElement>) {
    try {
      setIsSaving(true);
      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      const options = {
        headers: {
          Authorization: jwtToken,
        },
      };
      await API.post(
        "api",
        "contests/" + lockButtonInput.contestId + "/lock",
        options
      );
      setIsSaving(false);
    } catch (e) {
      alert(e);
      setIsSaving(false);
    }
  }

  function allPicksMade(user: string) {
    for (const game in lockButtonInput.games) {
      let props = lockButtonInput.games[game].props;
      let myProp = props[user];
      if (!myProp) {
        return false;
      }

      for (const creator in lockButtonInput.userStates) {
        if (user !== creator) {
          let otherProp = props[creator];
          if (!otherProp) {
            return false;
          }
          let myPick = otherProp.picks[user];
          if (!myPick) return false;
        }
      }
    }
    return true;
  }

  return (
    <Button
      variant="primary"
      onClick={lockPicks}
      disabled={isSaving || !allPicksMade(lockButtonInput.currentEmail)}
    >
      {isSaving && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      Lock Picks
    </Button>
  );
}
